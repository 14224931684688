import React, {useState} from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Page from "../../components/atoms/page";
import wood from "../../images/deals/wood.jpg";
import pizza from "../../images/deals/25-off/25-off.png";
import bogo from "../../images/deals/25-off/25-off.svg";
import './deals.css';
import Locator from "../../components/locator/Locator";
//import {stores} from "../../data/stores";
import {getParticipatingStores} from "../../helpers/location"
import MyModal from "../../components/atoms/modal/myModal";
import {graphql} from "gatsby";
import {deals_footer_text} from "../../helpers/constants";
import Img from "gatsby-image"

function TwentyFiveEntireOrder({data}) {
    const title = '25% Off Entire Order';
    let stores = data.allContentfulStores;

    const [isModalOpen, setIsModalOpen] = useState(false);

    function openModel() {
        setIsModalOpen(true);
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    let footerMessage = (
        <>
            <p>{deals_footer_text}</p>
        </>
    );

    let myStores = stores.edges;
    return (
        <Layout useMain={true} hideMenu={true} customFooterMessage={footerMessage}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page hideTitle={true} title={title} bgImage={wood} transparentPage={true}>


                <div className="flex flex-wrap">
                    <div className="w-full sm:w-full md:w-2/5 mb-4 md:pr-10 pb-10">

                        <div className="bg-black rounded-top">
                            <Img
                                fluid={data.textImage.fluid}
                                className="block mx-auto w-full"
                                alt="Combination and Pepperoni Pizza"
                            />

                            <p className={'text-white px-4 pb-5 sm:text-xl md:text-lg'}>
                                Enter code <span className={'font-bold text-red-700'}>PIZZA25</span> in the "Coupon Code" box to redeem offer. Limited time only!
                            </p>

                            <div className="bg-white order-now-div">
                            <span className="h2">
                                <a className={"text-red-800 font-bold sm:text-md md:text-3xl lg:text-4xl"} href={'#/'}
                                   onClick={openModel}>ORDER NOW ></a>
                            </span>
                            </div>

                            <div className="bg-black rounded-bottom">
                            </div>

                        </div>
                    </div>
                    <div className="w-full sm:w-full md:w-3/5 mb-4 md:pr-10 md:pb-10">
                        <Img
                            fluid={data.pizzaImage.fluid}
                            className="block mx-auto w-full"
                            alt="Combination and Pepperoni Pizza"
                        />
                    </div>
                </div>

                <MyModal title={'Start Order'} isOpen={isModalOpen} toggleModal={toggleModal}>
                    <div className={'bg-gray-100'}>
                        <Locator
                            storesPerPage={4}
                            showDetails={false}
                            showPhone={false}
                            stores={myStores}
                            allCompanyStores={stores.edges}
                        />
                    </div>
                </MyModal>


            </Page>
        </Layout>
    );
}

export default TwentyFiveEntireOrder;

export const query = graphql`query {
    allContentfulStores (filter: {node_locale: {eq: "en-US"}}) {
    edges {
      node {
        id
        contentful_id
        phoneNumber
        storeId
        visibleStoresMap
        specialUrl
        rating
        orderUrl
        menuUrl
        coordinates {
          lat
          lon
        }
        address {
          city
          country
          state
          streetName
          streetName2
          streetNumber
          streetSuffix
          zipCode
        }
      }
    }
  }
   textImage:imageSharp(fixed: {originalName: {eq: "25-off-text-no-coupon.png"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
      
        pizzaImage:imageSharp(fixed: {originalName: {eq: "25-off.png"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
}
  `;
